.Footer {
    color: #fff;
    display: flex;
    max-width: 700px;
    width: 96%;
    margin: 0px auto;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    margin-top: 140px;
    font-size: 14px;
}

.PoweredbyLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PoweredbyTitle {
    width: 100%;
    text-align: center;
}

.PoweredbyLogo img {
    display: block;
}
.PoweredbyLogo .op{
    margin-left: 10px;
    margin-right:10px;
}

.Copyright {
    margin-top: 20px;
}