.Detail {
    max-width: 700px;
    width: 96%;
    margin: 0px auto;
    color: #fff;

}

.DetailName {
    font-size: 20px;
    font-weight: bold;
}

.DetailVersion {
    margin-top: 10px;
}

.DetailDescription {
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #313131;
    color: #B4B4B4;
}

.DetailItem {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.DetailItemTitle {
    color: #B4B4B4;
}

.DetailItem a {
    text-decoration: none;
    color: #155DFD;
}

.DetailItem a:hover {
    text-decoration: underline;
}

.DetailReadmeTitle {
    color: #B4B4B4;
    margin-top: 20px;
}
.DetailItemValueWebsite{
    display: block;
}
.DetailItemValueMobile{
    display: none;
}
.DetailBack{
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: #fff;
    font-weight: bold;
    margin: 20px 0px;
    text-decoration: underline;
}

@media only screen and (max-width: 576px) {
    .DetailItemValueWebsite{
        display: none;
    }
    .DetailItemValueMobile{
        display: block;
    }
}