.MintContainer{
    color: #fff;
    max-width: 700px;
    width: 96%;
    margin: 0px auto;
}
.MintTitle{
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin-top: 100px;
}
.MintDescription{
    color: #B4B4B4;
    margin-top: 40px;
}
.MintNFTImage{
    width: 90%;
    max-width: 400px;
    margin: 0px auto;
    margin-top: 40px;
}
.MintButton{
    border-radius: 4px;
    cursor: pointer;
    margin: 0px auto;
    width: 300px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    color: #000;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin-top: 40px;
}
.MintWallet{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 100px;
}