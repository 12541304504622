.Header{
    color: #fff;
    display: flex;
    max-width: 700px;
    width: 96%;
    margin: 0px auto;
    justify-content: space-between;
    padding: 20px 0px;
}
.Header img{
    display: block;
}
.Nav{
    display: flex;
}
.Nav li{
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    margin-left: 40px;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
}

.Nav li a{
    color: #fff;
    text-decoration: none;
}

.Nav li a:hover{
    text-decoration: underline;
}