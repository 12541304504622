.List{
    max-width: 700px;
    width: 96%;
    margin: 0px auto;
}

.Banner{
    width: 100%;
    text-align: center;
    padding: 60px 0px;
    color: #fff;
}
.BannerTitle{
    font-size: 24px;
    font-weight: bold;
}
.BannerCopy{
    cursor: pointer;
    border-radius: 4px;
    margin: 0px auto;
    max-width: 330px;
    width: 90%;
    margin-top: 20px;
    font-size: 18px;
    border: 1px solid #313131;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.BannerCopyButton{
    font-size: 12px;
    border-radius: 4px;
    padding: 6px;
    background: #fff;
    color: #000;
    font-weight: bold;
}

.ListItemName{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}
.ListItem{
    font-weight: bold;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    padding: 10px;
    width: calc(100% - 22px);
    background: #181818;
    border: 1px solid #313131;
    border-radius: 4px;
    margin-bottom: 20px;
}

.List a{
    text-decoration: none;
}
.ListItemDetail{
    font-size: 14px;
    color: #fff;
    align-items: center;
    display: flex;
    margin-top: 20px;
}
.ListItemAddress{
    margin-left: 10px;
}
.ListItemVersion{
    margin-left: 10px;
}
.ListItemDate{
    margin-left: 10px;
}
.ListPage{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.ListItemDate{
    color: #B4B4B4;
}
